import { useEffect } from "react";

function moreContent ( { path, size, id, index }, adRendered = () => { } ) {

	const googletag = window.googletag || {};

	googletag.cmd = googletag.cmd || [];

	const currentSlotId = `${ id }-${ index }`;
	let adSlot          = null;

	// Define the slot itself, register it and fetch an ad.
	googletag.cmd.push ( function () {
		// Create new ad slot
		adSlot = googletag.defineSlot ( path, size, currentSlotId )
		// .setCollapseEmptyDiv ( true )
			.addService ( googletag.pubads () );

		window.adtest = adSlot;

		googletag.pubads ().setCentering ( true );
		// googletag.pubads ().set ( "adsense_test_mode", "on" );

		/* googletag.pubads ().addEventListener ( "slotRequested", event => {

    } );*/

		googletag.pubads ().addEventListener ( "slotRenderEnded", event => {

			/* const details = {
        "Advertiser ID"        : event.advertiserId,
        "Campaign ID"          : event.campaignId,
        "Company IDs"          : event.companyIds,
        "Creative ID"          : event.creativeId,
        "Creative Template ID" : event.creativeId,
        "Is backfill?"         : event.isBackfill,
        "Is empty?"            : event.isEmpty,
        "Label IDs"            : event.labelIds,
        "Line Item ID"         : event.lineItemId,
        Size                   : typeof event.size === "string" ? event.size :
          event.size?.join ( "x" ) ?? null,
        "Slot content changed?"        : event.slotContentChanged,
        "Source Agnostic Creative ID"  : event.sourceAgnosticCreativeId,
        "Source Agnostic Line Item ID" : event.sourceAgnosticLineItemId,
        "Yield Group IDs"              : event.yieldGroupIds
      }; */

			if ( !event.isEmpty )
				adRendered ();
		} );

		// Enable SRA to honor competetive exclusions and roadblocks.
		googletag.pubads ().enableSingleRequest ();

		// Enable services
		googletag.enableServices ();

		// Call display() to register the slot as ready and fetch an ad.
		googletag.display ( currentSlotId );

	} );

	// Return the slot reference for destruction later
	return () => {
		if ( adSlot ) {
			googletag.cmd.push ( () => {
				googletag.destroySlots ( [ adSlot ] );
			} );
		}
	};
}

const AdSlot = ( {
	path,
	size,
	id,
	index,
	adRendered
} ) => {
	const currentSlotId = `${ id }-${ index }`;

	useEffect ( () => {
		// Store the cleanup function returned by moreContent
		const destroyAdSlot = moreContent ( { path, size, id, index }, adRendered );

		// Cleanup when component unmounts
		return () => {
			if ( destroyAdSlot )
				destroyAdSlot ();

			const elem = document.getElementById ( "clever-90098-1294684-stickySponsorClick" );

			if ( elem )
				elem.remove ();
		};
	}, [] );

	return (
		<div id = { currentSlotId } />
	);
};

export default AdSlot;

